<template>
  <div class="studentDetails">
    <el-scrollbar class="elScroll">
      <template>
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-for="(item,index) in formData"
              :key="index"
              :label="item.stationName"
              align='center'
            >
              <el-form class="data_area">
                <ul>
                  <li>
                    <span>助学账号</span> 
                    <span v-if="item.loginNum==null">/</span>
                    <span v-else>{{item.loginNum}}</span>
                  </li>
                  <li>
                    <span>账号状态</span>
                    <span v-if="item.accountStatusName==null">/</span>
                    <span v-else>{{item.accountStatusName}}</span>
                  </li>
                  <li>
                    <span>学校</span>
                    <span v-if="item.schoolName==null">/</span>
                    <span v-else>{{item.schoolName}}</span>
                  </li>
                  <li>
                    <span>姓名</span>
                    <span v-if="item.name==null">/</span>
                    <span v-else>{{item.name}}</span>
                  </li>
                  <li>
                    <span>性别</span>
                    <span v-if="item.sex==null">/</span>
                    <span v-else>{{item.sex == 1 ? '女' : '男'}}</span>
                  </li>
                  <li>
                    <span>身份证号</span>
                    <span v-if="item.cardId==null">/</span>
                    <span v-else>{{item.cardId}}</span>
                  </li>
                  <li>
                    <span>手机号</span>
                    <span v-if="item.phone==null">/</span>
                    <span v-else>{{item.phone}}</span>
                  </li>
                  <li>
                    <span>招生教学点</span>
                    <span v-if="item.stationName==null">/</span>
                    <span v-else>{{item.stationName}}</span>
                  </li>
                  <li>
                    <span>招生批次</span>
                    <span v-if="item.admissionBatch==null">/</span>
                    <span v-else>{{item.admissionBatch}}</span>
                  </li>
                  <li>
                    <span>招生层次</span>
                    <span v-if="item.eduCategoryName==null">/</span>
                    <span v-else>{{item.eduCategoryName}}</span>
                  </li>
                  <li>
                    <span>招生专业</span>
                    <span v-if="item.majorName==null">/</span>
                    <span v-else>{{item.majorName}}</span>
                  </li>
                  <li>
                    <span>来源途径</span>
                    <span v-if="item.remarks==null">/</span>
                    <span v-else>{{item.remarks}}</span>
                  </li>
                  <li>
                    <span>备注</span>
                    <span v-if="item.source==null">/</span>
                    <span v-else>{{item.source}}</span>
                  </li>
                  <el-button @click="goBack">返回</el-button>
                </ul>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'studentDetails',
  data() {
    return {
      activeName: '0',
      classMessages: {},
      disputeFlag: '',
      id: '',
      tenantId: "",
      formData: {},
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.disputeFlag = newVal.query.disputeFlag;
        this.id = newVal.query.id;
        this.tenantId = newVal.query.tenantId;
        this.getList();
      }
    }
  },
  methods: {
    // 获取争议列表
    getList() {
      this.loading = true;
      let obj = {
        aidStudentId: this.id,
        tenantId: this.tenantId,
      };
      console.log(obj)
      this.$request.getDisputeList(obj, (res) => {
        this.loading = false;
        if (res.code == 0) {
          this.formData = res.data;
          // 手动传入name属性
          for(let i=0;i<this.formData.length;i++) {
            this.$set(this.formData[i], "tabsName", i)
            // if (this.formData[i].stationName == null) {
            //   this.formData[i].stationName = "暂无教学点信息"
            // }
          };
        }
      });
    },
    // 切换tab
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang='less'>
  .studentDetails {
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 40px 0 40px;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
      .data_area {
        width: 100%;
        padding-top: 20px;
        background: #FFFFFF;
        ul {
          // margin-left: 40px;
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          list-style-type: none;
          li {
            width: 100%;
            text-align: left;
            margin-bottom: 30px;
            display: flex;
            justify-content: space-around;
            span:nth-of-type(1) {
              flex: 1;
              height: 20px;
              font-size: 14px;
              display: inline-block;
              width: 80px;
            }
            span:nth-of-type(2) {
              flex: 7;
              margin-left: 30px;
              display: inline-block;
              width: 100px;
              // text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: #454545;
            }
          }
        }
      }
    }
  }
</style>